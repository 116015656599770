<template>
    <div style="width: 100%;height: 100%;">
      <img :src="imageUrl" @click="openPreview" style="cursor: pointer; width: 100%; height: 100%;" />
      <div v-if="showPreview" class="preview-overlay" @click="closePreview">
        <div class="preview-container">
          <img :src="imageUrl" class="preview-image" />
          <img :src="logoImg" style="position: absolute;top: 0;right: 0;width: 100px;height: 50px;" alt="">
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      imageUrl: {
        type: String,
        required: true
      }
    },
    data() {
      return {
        showPreview: false,
        logoImg: require("@/assets/sexs.png"),


      };
    },
    methods: {
      openPreview() {
        this.showPreview = true;
      },
      closePreview() {
        this.showPreview = false;
      }
    }
  };
  </script>
  
  <style>
  .preview-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }
  
  .preview-container {
    min-width: 80%;
    min-height: 80%;
    position: relative;
  }
  
  .preview-image {
    width: 100%;
    /* min-height: 100%; */
  }
  </style>
  