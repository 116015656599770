<template>
  <div class="classDetail">
    <!-- TODO 顶部搜索 -->
    <div style="background: #fff">
      <div class="row">
        <div class="content-center" style="cursor: pointer">
          <img
            :src="logoImges"
            class="img-Logo"
            @click="changeTitle({ key: 'home' })"
          />
          <p @click="govideoClass">视频学堂</p>
        </div>
        <div class="content-right">
          <a-input
            v-model="input"
            placeholder="输入关键词搜索"
          >
           <a-icon slot="prefix" type="search" @click="goclassroomSearch"/>
         </a-input>
        </div>
      </div>
    </div>

    <!-- TODO 详细部分 -->
    <div class="classDetail_footer">
      <!-- TODO 视频部分 -->
      <div class="classDetail_footer_contant">
        <div class="classDetail_footer_top">
          <p class="left" :title="videoDetail.title">
            {{ videoDetail.title }}
          </p>
          <div style="display: flex; align-items: center">
            <p style="margin: 0">国家地区</p>
            <a-tooltip placement="bottom">
              <template #title>
                <span>为了保证播放体验，请就近选择您所在的国家地区</span>
              </template>
              <div>
                  <img
                    style="cursor: pointer; width: 16px; height: 16px; margin: 0 10px 0 4px"
                    src="../../assets/smartcat/hint1.png"
                    alt=""
                  />
              </div>
            </a-tooltip>
            <!-- <el-tooltip
              class="item"
              effect="dark"
              content="为了保证播放体验，请就近选择您所在的国家地区"
              placement="bottom"
            >
              <div>
                <img
                  style="cursor: pointer; width: 16px; height: 16px; margin: 0 10px 0 4px"
                  src="../../assets/smartcat/hint1.png"
                  alt=""
                />
              </div>
            </el-tooltip> -->

            <a-select
              v-model="countryId"
              show-search
              placeholder="默认"
              dropdownClassName="drop"
              class="login-select"
              style="outline: none"
            >
              <a-icon
                style="margin-top: -2px"
                slot="suffixIcon"
                type="caret-down"
                :style="{ fontSize: '16px' }"
              />
              <a-select-option
                v-for="item in countryesnext"
                :key="item.id"
                :label="item.label"
                :value="item.value"
              >
                {{ item.label }}
              </a-select-option>
            </a-select>
          </div>
        </div>
        <div class="classDetail_footer_line">
          <p class="scriptes">共{{ videoDetail.lessons_list_count }}个章节</p>
          <p class="line">|</p>
          <p clscriptes>
            {{ videoDetail.click_number + videoDetail.click_offset }}人学过
          </p>
        </div>
        <div class="classDetail_footer_video">
          <div class="classDetail_footer_video_left">
            <!-- 留资提示 -->
            <div class="applyBox" v-if="applyStats">
              <p class="applyBox_title">申请免费观看权益</p>
              <p class="applyBox_line"></p>
              <p class="applyBox_btn" @click="applyNow" style="cursor: pointer">
                立即申请
              </p>
            </div>
            <!-- 视频播放 -->
            <div ref="videoPlay"></div>
            <!-- 图片显示 -->
            <image-preview
              v-if="typees == 1"
              @contextmenu="disableRightClick"
              :image-url="allUrl"
            />
            <!-- 文档展示 -->
            <iframe
              v-if="typees == 3 || typees == 4"
              :src="allUrl + '#toolbar=0'"
              style="width: 100%; height: 100%"
            ></iframe>
            <!-- txt显示 -->
            <pre
              style="
                max-width: 100%;
                max-height: 430px;
                white-space: pre-line;
                overflow-y: auto;
                margin: 0;
                padding: 10px;
                box-sizing: border-box;
              "
              v-if="typees == 5"
              >{{ allUrl }}</pre
            >
          </div>
          <div class="classDetail_footer_video_right">
            <div
              class="shareOne"
              @click="copyToClipboard"
              style="cursor: pointer; object-fit: cover"
            >
              <img
                class="imges"
                src="../../assets//smartcat/shareIcon.png"
                alt=""
              />
              <p>分享</p>
            </div>
            <p class="classDetail_footer_video_task">课程目录</p>
            <p class="classDetail_footer_video_line"></p>
            <div class="classDetail_footer_video_list">
              <div
                class="classDetail_footer_video_everyone"
                v-for="(item, index) in videoDetail.lessons_list"
                :key="index"
                target="mainFrame1"
                @click="highlightVideo($event, index)"
                :class="{ highlight1: index === currentVideoIndex }"
                style="cursor: pointer; object-fit: cover"
              >
                <div class="classDetail_footer_video_everyone_title">
                  <p
                    class="classDetail_footer_video_everyone_title_one"
                    :class="{ highlight2: index === currentVideoIndex }"
                  >
                    {{ index + 1 }}
                  </p>
                  <!-- <el-tooltip
                    style="margin-left: 5px"
                    effect="dark"
                    :content="item.study_lessons_list.material_name"
                    placement="bottom"
                  >
                    <p slot="content">
                      {{ item.study_lessons_list.material_name }}
                    </p>
                    <p>{{ item.study_lessons_list.material_name }}</p>
                  </el-tooltip> -->
                  <p :title="item.study_lessons_list.material_name">
                    {{ item.study_lessons_list.material_name }}
                  </p>
                </div>
                <div class="classDetail_footer_video_everyone_synopsis">
                  <p>
                    <img src="../../assets/smartcat/tvIcon.png" alt="" />
                    <span v-if="item.type == 1">照片</span>
                    <span v-else-if="item.type == 2">视频</span>
                    <span v-else-if="item.type == 3">文档</span>
                  </p>
                  <!-- <p>
                    <img src="../../assets/smartcat/timeIico.png" alt="" />
                    <span>24.22</span>
                  </p> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- TODO 课程简介 -->
      <div class="courseIntroduction">
        <div class="courseIntroduction_left">
          <p class="">课程简介</p>
          <div v-html="videoDetail.describe"></div>
        </div>
        <div class="courseIntroduction_right">
          <p class="courseIntroduction_right_title">相关课程</p>
          <p class="courseIntroduction_right_line"></p>
          <div class="courseIntroduction_list">
            <div
              class="courseIntroduction_list_everyone"
              style="cursor: pointer"
              v-for="(item, index) in other_lessons[0]
                ? other_lessons[0].lessons_info
                : ''"
              :key="index"
              @click="goclassDetail(other_lessons[0].id, item.id)"
            >
              <div style="width: 140px; height: 80px">
                <img
                  style="cursor: pointer; object-fit: cover"
                  :src="item.cover_images"
                  alt=""
                />
              </div>
              <div class="courseIntroduction_list_right">
                <p class="courseIntroduction_list_title" :title="item.title">
                  {{ item.title }}
                </p>
                <div class="courseIntroduction_list_section">
                  <p class="normal_look">
                    共{{ item.lessons_list_count }}个章节
                  </p>
                  <p class="normal_line">|</p>
                  <p class="normal_look">
                    {{ item.click_number + item.click_offset }}人学过
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- TODO 留资弹窗 -->
    <div
      style="
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(176, 176, 176, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 999;
      "
      v-if="reservedState"
    >
      <div class="reserved_box">
        <div class="reserved_tit">
          <p style="font-weight: bold; font-size: 20px">申请免费观看权益</p>
          <i
            style="
              font-size: 20px;
              font-weight: bold;
              color: rgba(40, 47, 54, 0.3);
              cursor: pointer;
            "
            @click="exitOut"
            class="el-icon-close"
          ></i>
        </div>
        <div class="reserved_everyone">
          <img src="../../assets/smartcat/retainIcon1.png" alt="" />
          <p></p>
          <input
            style="margin-left: 14px; border: none; outline: none"
            class="input"
            v-model="reservedData.name"
            placeholder="请填写您的姓名"
          />
        </div>
        <div class="reserved_everyone">
          <img src="../../assets/smartcat/retainIcon2.png" alt="" />
          <p></p>
          <a-select
            v-model="reservedData.country"
            show-search
            placeholder="选择区号"
            dropdownClassName="drop"
            class="login-select"
          >
            <a-icon
              style="margin-top: -2px"
              slot="suffixIcon"
              type="caret-down"
              :style="{ fontSize: '16px' }"
            />
            <a-select-option
              v-for="item in countryes"
              :key="item.id"
              :label="'+' + item.timezone"
              :value="item.timezone"
            >
              +{{ item.timezone }}
            </a-select-option>
          </a-select>
          <input
            style="margin-left: 14px; border: none; outline: none"
            class="input"
            v-model="reservedData.mobile"
            placeholder="请填写您的手机号"
          />
        </div>
        <div class="reserved_everyone">
          <img src="../../assets/smartcat/retainIcon3.png" alt="" />
          <p></p>
          <input
            style="margin-left: 14px; border: none; outline: none"
            class="input"
            v-model="reservedData.wechat"
            placeholder="请填写您的微信号"
          />
        </div>
        <div class="reserved_everyone">
          <img src="../../assets/smartcat/retainIcon4.png" alt="" />
          <p></p>
          <input
            style="margin-left: 14px; border: none; outline: none"
            class="input"
            v-model="reservedData.email"
            placeholder="请填写您的邮箱（选填）"
          />
        </div>
        <p class="subBtn" style="cursor: pointer" @click="outReserved">
          确认提交
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { Message } from "element-ui";
import ImagePreview from "../../components/imgPre.vue";
import "video.js/dist/video-js.css";
import videojs from "video.js";
export default {
  name: "SmartcatplusIndex",

  data() {
    return {
      logoImg: require("@/assets/sexs.png"),
      logoImges: require("@/assets/smartcat/logo.svg"),
      input: "",
      videoUrl:
        "https://beego-study-1252088954.cos.ap-hongkong.myqcloud.com/resources/20231201/1701400788_sZ2UPPEq7wHcNea8.mov",
      cover: "https://img1.wxzxzj.com/vpc-example-cover-your-name-c.png",
      bgImges: require("@/assets/sexs.png"),
      currentVideoIndex: 0,
      videoDetail: {},
      other_lessons: [],
      typees: null,
      allUrl: "",
      imageUrl: "",
      player: "",
      nowId: "",
      nowTime: Math.floor(Date.now() / 1000),
      play_total_time: 0,
      play_time: 0,
      reservedState: false,
      reservedData: {
        name: "",
        country: undefined,
        mobile: "",
        wechat: "",
        email: "",
      },
      countryes: [
        {
          value: "",
          label: "",
        },
      ],
      countryId: undefined,
      countryesnext: [
        {
          value: "beego-gz-study-1252088954",
          label: "国内",
        },
        {
          value: "beego-study-1252088954",
          label: "海外",
        },
      ],
      applyStats: false,
      isFirstLoad: true,
      timeStats:false
    };
  },
  computed: {},
  created() {
    this.getvideoDetail(
      this.$route.query.column_id,
      this.$route.query.lessons_id
    );
    this.getCountry();
  },
  components: {
    ImagePreview,
  },
  watch: {
    videoDetail: {
      handler() {
        if (this.videoDetail.lessons_list) {
          this.currentVideoUrl();
        }
      },
      deep: true,
    },
    reservedState: function (newVal, oldVal) {
      if (newVal == true) {
        window.addEventListener("wheel", this.handleStopWheel, {
          passive: false,
        });
      } else {
        window.removeEventListener("wheel", this.handleStopWheel);
      }
      deep: true;
    },
  },
  beforeDestroy() {
    this.getplayMessage();
  },
  mounted() {},
  updated() {},
  methods: {
    handleStopWheel(e) {
      e.preventDefault();
    },
    applyNow() {
      this.reservedState = true;
    },
    // 提交留资信息
    outReserved() {
      let msg = "";
      if (!this.reservedData.name) {
        msg = "请输入姓名";
      } else if (!this.reservedData.country) {
        msg = "请输入区号";
      } else if (!this.reservedData.mobile) {
        msg = "请输入手机号";
      } else if (!this.reservedData.wechat) {
        msg = "请输入微信号";
      }
      if (msg) {
        this.$message.warning(msg);

        return;
      }

      const params = {
        name: this.reservedData.name,
        country: this.reservedData.country,
        mobile: this.reservedData.mobile,
        wechat: this.reservedData.wechat,
        email: this.reservedData.email,
      };

      this.$request.getoutReserved(params).then((res) => {
        if (res.ret) {
          this.$message.success("提交成功");
          this.reservedState = false;
          window.location.reload();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    //区号
    getCountry() {
      this.$request.country().then((res) => {
        if (res.ret) {
          this.countryes = res.data;
        }
      });
    },
    exitOut() {
      this.reservedState = false;
    },
    // 视频加水印
    async initializeVideoPlayer() {
      this.$nextTick(() => {
        // const video = document.createElement(``)
        this.$refs.videoPlay.innerHTML = `<video
			  class="video-js vjs-default-skin"
              id="video"
              controls="true"
              poster="${this.cover}"
              autoplay
              oncontextmenu="return false"
              controlslist="nodownload"
              preload="auto"
              webkit-playsinline="true"
              playsinline="true"
              x-webkit-airplay="allow"
              x5-video-player-type="h5"
              x5-video-player-fullscreen="true"
              x5-video-orientation="portraint"
              style="object-fit: fill; width: 100%; height: 100%"
            >
			<source src="${this.allUrl}">
			</video>`;
        const dynamicButton = document.getElementById("video");
        if (dynamicButton) {
          dynamicButton.addEventListener(
            "loadedmetadata",
            this.getVideoDuration
          );
          dynamicButton.addEventListener("timeupdate", this.getPlayTime);
          dynamicButton.addEventListener("ended", this.endedNext);
          this.$refs.videoPlayer = dynamicButton;
        }
        const videoOptions = {
          controls: true,
          fluid: true,
        };
        const _this = this;
        // console.log(document.getElementById('video'))
        this.player = videojs(
          document.getElementById("video"),
          videoOptions,
          function onPlayerReady() {
            _this.addWatermark();
          }
        );
      });
    },
    addWatermark() {
      // 创建水印元素
      const watermark = document.createElement("div");
      watermark.className = "vjs-watermark"; // 替换成实际的水印文本

      // 将水印元素添加到播放器容器中
      this.player.el().appendChild(watermark);
    },
    // 获取视频时长
    getVideoDuration() {
      const videoElement = this.$refs.videoPlayer;
      this.play_total_time = Math.floor(videoElement.duration)
        ? Math.floor(videoElement.duration)
        : 0;
    },
    // 获取视频播放时长
    getPlayTime() {
      const videoElement = this.$refs.videoPlayer;
      this.play_time = Math.floor(videoElement.currentTime)
        ? Math.floor(videoElement.currentTime)
        : 0;
        // 当视频播放时长大于40%的时候出现留资弹窗
        if(this.play_total_time && this.play_time >= this.play_total_time * 0.4 && this.timeStats == false){
            this.reservedState = true
            this.applyStats =true
            if(this.player){
              videoElement.pause()
            }
        }
    },
    // 上报用户播放信息
    getplayMessage() {
      if (this.isFirstLoad) {
        this.isFirstLoad = false;
      } else {
        const params = {
          column_id: this.$route.query.column_id,
          lessons_id: this.$route.query.lessons_id,
          material_id: this.nowId,
          play_total_time: this.play_total_time,
          play_start_time: this.nowTime,
          play_time: this.play_time,
          companies_id: 0,
        };
        this.$request.getplayMessage(params).then((res) => {});
      }
    },
    // 获取视频临时路径
    getTemporaryurl(id) {
      this.$request
        .getTemporaryurl(id, { endpoint: this.countryId })
        .then((res) => {
          if (res.ret && res.data.is_play == false) {
            this.allUrl = res.data.view_url;
            this.timeStats = false
            if (this.allUrl && this.typees == 2) {
              if (!this.player) {
                this.initializeVideoPlayer();
              }
            }
          } else {
            this.allUrl = res.data.view_url;
            this.timeStats = true
            if (this.allUrl && this.typees == 2) {
              if (!this.player) {
                this.initializeVideoPlayer();
              }
            }
            // this.reservedState = true;
            // this.applyStats = true;
          }
        });
    },
    // 获取文档临时路径
    getTemporaryurlnext(id) {
      this.$request.getTemporaryurl(id).then((res) => {
        if (res.ret) {
          this.allUrl =
            "https://view.officeapps.live.com/op/view.aspx?src=" +
            encodeURIComponent(res.data.view_url);
        }
      });
    },
    // 获取txt临时路径
    getTemporaryurltxt(id) {
      this.$request.getTemporaryurl(id).then((res) => {
        if (res.ret) {
          fetch(res.data.view_urll)
            .then((response) => {
              if (!response.ok) {
                throw new Error("Network response was not ok");
              }
              return response.text();
            })
            .then((text) => {
              // 将获取到的纯文本存储到变量中，以便在页面上显示
              this.allUrl = text;
            })
            .catch((error) => {
              console.error("加载失败");
            });
        }
      });
    },
    // 预览图片
    previewImg(url) {
      this.imageUrl = url;
    },
    // 阻止默认的右键菜单行为
    disableRightClick(event) {
      event.preventDefault();
    },
    // 视频首页
    govideoClass() {
      this.$router.push({
        path: "/videoClass",
      });
    },
    // 根据返回类型判断左侧内容
    currentVideoUrl() {
      if (this.videoDetail && this.videoDetail.lessons_list) {
        const currentVideo =
          this.videoDetail.lessons_list[this.currentVideoIndex];
        this.nowId = currentVideo.id;
        if (currentVideo.type == 1) {
          this.typees = 1;
          this.getTemporaryurl(currentVideo.id);
        } else if (currentVideo.type == 2) {
          this.typees = 2;
          this.getTemporaryurl(currentVideo.id);
        } else if (currentVideo.type == 3 && currentVideo.suffix == "pdf") {
          this.typees = 3;
          this.getTemporaryurl(currentVideo.id);
        } else if (currentVideo.type == 3 && currentVideo.suffix == "txt") {
          this.typees = 5;
          fetch(currentVideo.view_url)
            .then((response) => {
              if (!response.ok) {
                throw new Error("Network response was not ok");
              }
              return response.text();
            })
            .then((data) => {
              this.allUrl = data;
            })
            .catch(() => {
              // console.error(
              //   "There has been a problem with your fetch operation:",
              //   error
              // );
            });
        } else {
          this.typees = 4;
          this.getTemporaryurlnext(currentVideo.id);
        }
        this.getplayMessage();
        this.play_total_time = 0;
      }
    },
    // 返回首页
    changeTitle(item) {
      this.$router.push(item.key);
      this.$store.commit("updateCurrentPage", "home");
    },
    goclassDetail(column_id, lessons_id) {
      this.$router.replace({
        path: "/classDetail",
        query: {
          column_id,
          lessons_id,
        },
      });
      window.location.reload();
    },
    // 课程详细
    getvideoDetail(id, ids) {
      this.$request.videoDetail(id, ids).then((res) => {
        if (res.ret) {
          this.videoDetail = res.data.lessons_info;
          this.other_lessons = res.data.other_lessons;
          this.cover = res.data.lessons_info.cover_images;
        }
      });
    },
    // 分享课程
    copyToClipboard() {
      navigator.clipboard
        .writeText(window.location.href)
        .then(() => {
          Message({
            message: "课程链接已复制到剪贴板",
            type: "success",
          });
        })
        .catch(() => {
          Message.error("复制失败");
        });
    },
    // 右侧切换
    highlightVideo(event, index) {
      const videoElements = document.querySelectorAll(
        ".classDetail_footer_video_everyone"
      );

      const videoElementsnext = document.querySelectorAll(
        ".classDetail_footer_video_everyone_title_one"
      );
      videoElements.forEach(function (element) {
        element.classList.remove("highlight1");
      });
      videoElementsnext.forEach(function (element) {
        element.classList.remove("highlight2");
      });
      const clickedVideo = videoElements[index];
      const clickedVideoes = videoElementsnext[index];
      clickedVideo.classList.add("highlight1");
      clickedVideoes.classList.add("highlight2");
      this.currentVideoIndex = index;
      if (this.typees == 2 && this.player) {
        this.player.dispose();
        this.player = "";
        this.applyStats =false
      }
      this.currentVideoUrl();
      this.$nextTick(() => {
        if (this.typees == 2 && this.player) {
          this.$refs.videoPlayer.play();
        }
      });
    },
    // 自动切换一下
    endedNext() {
      if (this.currentVideoIndex < this.videoDetail.lessons_list.length - 1) {
        // 切换到下一个视频
        this.currentVideoIndex++;
        const nextLesson =
          this.videoDetail.lessons_list[this.currentVideoIndex];
        if (nextLesson.type == 2) {
          // 如果下一个是视频，自动播放
          this.$refs.videoPlayer.src = nextLesson.view_url;

          this.highlightVideo(null, this.currentVideoIndex);
          setTimeout(() => {
            this.$refs.videoPlayer.play();
          }, 100);
        } else {
          // 如果下一个不是视频，自动跳过
          this.endedNext();
        }
      } else {
        // 没有下一个视频，暂停播放
        this.$refs.videoPlayer.pause();
        // this.highlightVideo(null, this.currentVideoIndex);
      }
    },
    goclassroomSearch() {
      this.$router.push({
        path: "/classroomSearch",
        query: {
          value: this.input,
        },
      });
    },
    beforeDestroy() {
      this.player.dispose();
      this.player = "";
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/.ant-select-selection {
  border-color: #f8f8f8;
  border-color: rgba(238, 238, 238, 1);
}
/deep/.ant-select-selection {
  // border: none !important;
  box-shadow: none;
  outline: none;
}
.applyBox {
  width: 100%;
  height: 100%;
  background: rgba(51, 51, 51, 1);
  position: absolute;
  left: 0;
  z-index: 99;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .applyBox_title {
    margin: 0;
    font-size: 16px;
    font-weight: 700;
    line-height: 22.4px;
    color: rgba(255, 255, 255, 1);
  }
  .applyBox_line {
    margin: 0;
    width: 240px;
    height: 1px;
    background: rgba(102, 102, 102, 1);
    margin-top: 25px;
  }
  .applyBox_btn {
    margin: 0;
    width: 160px;
    height: 30px;
    border-radius: 15px;
    background: rgba(255, 208, 0, 1);
    font-size: 14px;
    font-weight: 400;
    line-height: 30px;
    text-align: center;
    color: rgba(51, 51, 51, 1);
    margin-top: 21px;
  }
}
.reserved_box {
  width: 400px;
  height: auto;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 10px;
  padding: 30px;
}
.reserved_tit {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}
.reserved_everyone {
  display: flex;
  border: 1px solid rgba(51, 51, 51, 0.1);
  padding: 11px 10px;
  align-items: center;
  border-radius: 5px;
  margin-top: 20px;
  /deep/.ant-select-selection {
    width: 100px;
    border: none;
    outline: none;
  }
  p {
    margin: 0;
    margin-left: 14px;
    width: 1px;
    height: 15px;
    background-color: rgba(51, 51, 51, 0.1);
  }
  img {
    width: 20px;
    color: rgba(40, 47, 54, 0.3);
  }
  /deep/.el-input__inner {
    width: 100px;
    height: 36px;
    // border-radius: 20px 0px 0 20px;
    background-color: #fff;
    padding-top: 2px;
    border: none;
    outline: none;
  }
  // border-right: 0;
  input::-webkit-input-placeholder {
    color: #ccc;
  }
}
.reserved_everyone:nth-child(3) {
  padding: 5px 10px;
}
.subBtn {
  width: 136px;
  height: 40px;
  border-radius: 20px;
  background: rgba(255, 208, 0, 1);
  font-size: 14px;
  line-height: 40px;
  text-align: center;
  margin-top: 40px;
}
::v-deep .vjs-watermark {
  position: absolute;
  width: 150px;
  height: 50px;
  background-image: url("../../assets/sexs.png");
  background-repeat: no-repeat;
  background-size: cover;
  top: 10px;
  right: 10px;
  /* transform: translate(-50%, -50%); */
  font-size: 24px;
  color: rgba(255, 255, 255, 0.5);
  /* 水印颜色和透明度 */
  pointer-events: none;
  /* 防止水印遮挡视频控件 */
}
.classDetail {
  min-height: 100vh;
  background: #f8f8f8;
  .highlight1 {
    background: rgba(255, 255, 255, 1);
  }
  .highlight2 {
    background: rgba(255, 208, 0, 1);
  }
  .row {
    width: 1200px;
    margin: auto;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    line-height: 64px;
  }
  .content-center {
    display: flex;
    align-items: center;
    .img-Logo {
      width: 146px;
      height: 40px;
    }
    p {
      width: 80px;
      font-size: 20px;
      font-weight: 700;
      margin: 0;
      color: rgba(0, 0, 0, 1);
      margin-left: 30px;
    }
  }
  .content-right {
    /deep/.ant-input {
      border-radius: 20px;
      background: rgba(248, 248, 248, 1);
      border: none;
      width: 600px;
      height: 36px;
      padding-left: 50px;
    }
    /deep/.ant-input:hover,ant-input:focus {
      cursor: pointer;
      border-color: transparent !important;
      box-shadow: none !important;
    }
  }
  .classDetail_footer {
    background: #f8f8f8;
    height: auto;
    padding-top: 20px;
    padding-bottom: 50px;
  }
  .classDetail_footer_contant {
    width: 1200px;
    height: auto;
    margin: auto;
    padding: 30px;
    border-radius: 10px;
    background: rgba(255, 255, 255, 1);
    .classDetail_footer_top {
      height: 40px;
      display: flex;
      justify-content: space-between;
      /deep/.ant-select-selection__placeholder {
        margin-left: 10px;
      }
      /deep/.ant-select-selection {
        width: 106px;
        border-radius: 20px;
      }
      .ant-select-selection:hover {
        background-color: white;
      }
      .left {
        height: 34px;
        font-size: 24px;
        font-weight: 700;
        color: rgba(0, 0, 0, 1);
        max-width: 768px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .classDetail_footer_line {
      height: 28px;
      display: flex;
      line-height: 28px;
      .scriptes {
        font-size: 14px;
        font-weight: 400;
        color: rgba(136, 136, 136, 1);
      }
      .line {
        height: 14px;
        color: rgba(238, 238, 238, 1);
        margin: 0 10px;
      }
    }
    .classDetail_footer_video {
      height: 432px;
      background: rgba(248, 248, 248, 1);
      display: flex;
      .classDetail_footer_video_left {
        width: 768px;
        height: 432px;
        position: relative;
      }
      .classDetail_footer_video_right {
        flex: 1;
        padding: 10px 20px;
        position: relative;
        .shareOne {
          width: 82px;
          height: 32px;
          line-height: 32px;
          border-radius: 20px;
          background: rgba(255, 208, 0, 1);
          display: flex;
          position: absolute;
          right: 20px;
          .imges {
            width: 16px;
            height: 16px;
            margin-left: 15px;
            margin-top: 8px;
          }
          p {
            font-size: 14px;
            font-weight: 400;
            height: 20px;
            color: rgba(0, 0, 0, 1);
            margin-left: 6px;
            margin-top: 0px;
          }
        }
        .classDetail_footer_video_task {
          height: 28px;
          font-size: 20px;
          font-weight: 700;
          line-height: 28px;
          margin: 0;
          color: rgba(0, 0, 0, 1);
        }
        .classDetail_footer_video_line {
          width: 100%;
          height: 1px;
          background: rgba(238, 238, 238, 1);
          margin: 0px;
          margin-top: 16px;
        }
        .classDetail_footer_video_list {
          margin-top: 20px;
          max-height: 340px;
          overflow-y: auto;
          overflow-x: hidden;
          .classDetail_footer_video_everyone {
            width: 332px;
            height: 80px;
            margin-bottom: 10px;
            border-radius: 5px;

            .classDetail_footer_video_everyone_title {
              display: flex;
              align-items: baseline;
              height: 30px;
              line-height: 30px;
              padding-top: 10px;
              .classDetail_footer_video_everyone_title_one {
                margin: 0;
                width: 20px;
                // background: rgba(255, 208, 0, 1);
                border-radius: 50%;
                font-size: 14px;
                font-weight: 700;
                text-align: center;
                line-height: 20px;
                margin-left: 10px;
              }
              p:nth-child(2) {
                margin: 0;
                font-size: 14px;
                font-weight: 700;
                color: rgba(68, 68, 68, 1);
                margin-left: 10px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }
            .classDetail_footer_video_everyone_synopsis {
              margin-top: 10px;
              padding: 0 20px 0 40px;
              display: flex;
              line-height: 30px;
              justify-content: space-between;
              p {
                span {
                  font-size: 14px;
                  color: rgba(136, 136, 136, 1);
                  margin-left: 8px;
                }
              }
            }
          }
          .classDetail_footer_video_everyone:hover {
            background: rgba(255, 255, 255, 1);
          }
        }
        // .classDetail_footer_video_list::-webkit-scrollbar {
        //   display: none;
        // }
      }
    }
  }
  .courseIntroduction {
    width: 1200px;
    height: auto;
    margin: auto;
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    .courseIntroduction_left {
      width: 798px;
      height: auto;
      border-radius: 10px;
      padding: 26px 30px;
      background: rgba(255, 255, 255, 1);
      word-wrap: break-word;
      /deep/img {
        max-width: 100% !important;
        margin: 0;
      }
      p:nth-child(1) {
        font-size: 20px;
        font-weight: 700;
        line-height: 28px;
        color: rgba(0, 0, 0, 1);
      }
      p:nth-child(2) {
        font-size: 16px;
        font-weight: 400;
        letter-spacing: 0px;
        line-height: 24px;
        color: rgba(68, 68, 68, 1);
      }
      p:nth-child(3) {
        font-size: 16px;
        font-weight: 400;
        letter-spacing: 0px;
        line-height: 24px;
        color: rgba(136, 136, 136, 1);
        margin-top: 20px;
      }
    }
    .courseIntroduction_right {
      width: 382px;
      height: auto;
      padding-bottom: 20px;
      border-radius: 10px;
      padding: 26px 20px;
      background: rgba(255, 255, 255, 1);
      .courseIntroduction_right_title {
        font-size: 20px;
        font-weight: 700;
        line-height: 28px;
        color: rgba(0, 0, 0, 1);
      }
      .courseIntroduction_right_line {
        height: 1px;
        background: rgba(238, 238, 238, 1);
        margin: 0;
      }
      .courseIntroduction_list {
        overflow-y: auto;
        min-height: 400px;
        // overflow-x: hidden;
        min-width: 340px;
        .courseIntroduction_list_everyone {
          height: 80px;
          margin-top: 20px;
          display: flex;
          img {
            width: 140px;
            height: 80px;
            border-radius: 5px;
            flex: 1;
          }
          .courseIntroduction_list_right {
            padding: 0 10px;
            .courseIntroduction_list_title {
              max-width: 200px;
              font-size: 14px;
              font-weight: 400;
              line-height: 30px;
              color: rgba(0, 0, 0, 1);
              white-space: nowrap; /* 禁止换行 */
              overflow: hidden; /* 超出部分隐藏 */
              text-overflow: ellipsis; /* 显示省略号 */
            }
            .courseIntroduction_list_section {
              display: flex;
              margin-top: 30px;
              .normal_look {
                font-size: 14px;
                font-weight: 400;
                color: rgba(136, 136, 136, 1);
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
              }
              .normal_line {
                height: 14px;
                color: rgba(238, 238, 238, 1);
                margin: 0;
                margin: 0 10px;
                // flex: 1;
              }
            }
          }
        }
        .courseIntroduction_list_everyone:hover {
        }
      }
      .courseIntroduction_list::-webkit-scrollbar {
        display: none;
      }
    }
  }
}
</style>
<style >
ul > .ant-select-dropdown-menu-item-active {
  background: #eee !important;
}
</style>
